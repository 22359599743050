import createReactComponent from '../createReactComponent';
export default createReactComponent('cylinder-plus', 'IconCylinderPlus', [
  ['path', { d: 'M12 6m-7 0a7 3 0 1 0 14 0a7 3 0 1 0 -14 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 6v12c0 1.657 3.134 3 7 3c.173 0 .345 -.003 .515 -.008m6.485 -8.992v-6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
