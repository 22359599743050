import createReactComponent from '../createReactComponent';
export default createReactComponent('droplets', 'IconDroplets', [
  [
    'path',
    {
      d: 'M4.072 20.3a2.999 2.999 0 0 0 3.856 0a3.002 3.002 0 0 0 .67 -3.798l-2.095 -3.227a.6 .6 0 0 0 -1.005 0l-2.098 3.227a3.003 3.003 0 0 0 .671 3.798z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16.072 20.3a2.999 2.999 0 0 0 3.856 0a3.002 3.002 0 0 0 .67 -3.798l-2.095 -3.227a.6 .6 0 0 0 -1.005 0l-2.098 3.227a3.003 3.003 0 0 0 .671 3.798z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10.072 10.3a2.999 2.999 0 0 0 3.856 0a3.002 3.002 0 0 0 .67 -3.798l-2.095 -3.227a.6 .6 0 0 0 -1.005 0l-2.098 3.227a3.003 3.003 0 0 0 .671 3.798z',
      key: 'svg-2',
    },
  ],
]);
