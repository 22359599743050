import createReactComponent from '../createReactComponent';
export default createReactComponent('device-projector', 'IconDeviceProjector', [
  ['path', { d: 'M8 9a5 5 0 1 0 10 0a5 5 0 0 0 -10 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 6h-4a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-8a2 2 0 0 0 -2 -2h-2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 15h1', key: 'svg-2' }],
  ['path', { d: 'M7 18l-1 2', key: 'svg-3' }],
  ['path', { d: 'M18 18l1 2', key: 'svg-4' }],
]);
